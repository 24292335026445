import {ProxyToProxyDTO} from "../models/ProxyToProxyDTO";
import {Page} from "../pagination/Page";
import {SortingConfiguration} from "../utils/SortingUtils";
import axiosInstance from "../network/AxiosInstance";

export class ProxyToProxyService {
    static async findAllPaged(
        page: number,
        size: number,
        name?: string,
        url?: string,
        countryId?: number,
        dateCreated?: string,
        dateModified?: string,
        sortingConfiguration?: SortingConfiguration
    ): Promise<Page<ProxyToProxyDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (name) {
            params.append("name", name);
        }

        if (url) {
            params.append("url", url);
        }

        if (countryId !== undefined) {
            params.append("countryId", countryId.toString());
        }

        if (dateCreated) {
            params.append("dateCreated", dateCreated);
        }

        if (dateModified) {
            params.append("dateModified", dateModified);
        }

        if (sortingConfiguration) {
            sortingConfiguration.appendToBackEndSearchParams(params);
        }

        const response = await axiosInstance.get<Page<ProxyToProxyDTO>>(
            `/rest/bot-session/proxy-to-proxy/all-paged`, {params: params},
        );

        const data = response.data;

        return new Page<ProxyToProxyDTO>(
            data.content.map(proxyToProxy => new ProxyToProxyDTO(proxyToProxy)),
            data.totalElements,
        )
    }
}